import {AnyAction} from 'redux'
import {SEND_RSVP} from '../actions/rsvp'
import {GET_ORDER} from '../actions/placed-order'

export const calendarLinks = (state = null, action: AnyAction) => {
  switch (action.type) {
    case SEND_RSVP.SUCCESS:
    case GET_ORDER.SUCCESS:
      return {
        ...action.payload.calendarLinks,
      }
    default:
      return state
  }
}
