import {combineReducers} from 'redux'
import {dates} from '../../../commons/reducers/dates'
import {demoEvents} from '../../../commons/reducers/demo-events'
import {environment} from '../../../commons/reducers/environment'
import {experiments} from '../../../commons/reducers/experiments'
import {multilingual} from '../../../commons/reducers/multilingual'
import {siteSettings} from '../../../commons/reducers/site-settings'
import {State} from '../types'
import {instance} from '../../../commons/reducers/instance'
import {checkoutOptions} from './checkout-options'
import {component} from './component'
import {currentMemberDetails} from './current-member-details'
import {event} from './event'
import {memberRsvp} from './member-rsvp'
import {membersAreaEnabled} from './members-area-enabled'
import {navigation} from './navigation'
import {paidPlans} from './paid-plans'
import {paidPlansEnabled} from './paid-plans-enabled'
import {pendingRequests} from './pending-requests'
import {reservation} from './reservation'
import {selectedTickets} from './selected-tickets'
import {tickets} from './tickets'
import {ticketsPicker} from './tickets-picker'
import {placedOrder} from './placed-order'
import {checkout} from './checkout'
import {invoice} from './invoice'
import {calendarLinks} from './calendar-links'
import {guest} from './guest'
import {policies} from './policies'
import {rsvp} from './rsvp'
import {modals} from './modals'
import {schedule} from './schedule'
import {currentUser} from './current-user'
import {upcomingOccurrencesCount} from './upcoming-occurrences-count'
import {groups} from './groups'

export default combineReducers<State>({
  experiments,
  environment,
  siteSettings,
  multilingual,
  demoEvents,
  event,
  component,
  navigation,
  currentMemberDetails,
  memberRsvp,
  membersAreaEnabled,
  paidPlansEnabled,
  checkoutOptions,
  selectedTickets,
  pendingRequests,
  tickets,
  reservation,
  ticketsPicker,
  paidPlans,
  instance,
  placedOrder,
  checkout,
  invoice,
  calendarLinks,
  guest,
  policies,
  rsvp,
  modals,
  schedule,
  dates,
  currentUser,
  upcomingOccurrencesCount,
  groups,
})
