import {EventsDates} from '@wix/events-moment-commons'
import {CommonDates} from '../types/state'

export const UPDATE_DATES = 'UPDATE_DATES'

export const updateDates =
  ({events, common}: {events: EventsDates; common: CommonDates}) =>
  dispatch =>
    dispatch({
      type: UPDATE_DATES,
      payload: {
        events,
        common,
      },
    })
