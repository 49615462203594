import {AnyAction} from 'redux'
import {CLOSE_NATIVE_MODAL, OPEN_NATIVE_MODAL} from '../actions/modals'
import {ModalsState} from '../types'

const defaultState: ModalsState = {
  modalType: '',
}

export const modals = (state = defaultState, action: AnyAction): ModalsState => {
  switch (action.type) {
    case OPEN_NATIVE_MODAL:
      return {modalType: action.payload.modalType}
    case CLOSE_NATIVE_MODAL:
      return defaultState
    default:
      return state
  }
}
