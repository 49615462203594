import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {IWixStyleParams} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {
  BiParams,
  createUouBiMiddlewareWithBiParams,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
  GROUPS_APP_DEF_ID,
  GROUPS_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import {ReservationStatus} from '@wix/events-types'
import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {decodeInstance} from '../../commons/selectors/instance'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSchedulePageInstalled} from '../../commons/utils/wix-code-api'
import {getExperimentsByScope} from '../../commons/utils/experiments'
import {getExtraEventData} from './actions/event'
import {addLoginListener, fetchCurrentMember} from './actions/members'
import {addLocationListener, handleInitialNavigation} from './actions/navigation'
import {getMemberRsvp} from './actions/rsvp'
import * as eventsUou from './bi/uou-bi-events-map'
import {DetailsPageProps} from './components/app/interfaces'
import {datesMiddleware} from './middlewares/date'
import reducers from './reducers'
import {defaultInvoiceState} from './reducers/invoice'
import {getDemoEvent} from './services/demo-event'
import type {Navigation, State, StoreExtraArgs} from './types'
import {userEventsLogger} from './user-events-logger'
import {Api} from './utils/api'
import {parseLocation} from './utils/navigation'
import {getDraftToken} from './utils/query'
import {getExportedActions} from './actions/exported-actions'

const createDetailsPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  return {
    async pageReady() {
      const {wixCodeApi} = controllerParams.controllerConfig
      const {flowAPI} = controllerParams
      const language = getLanguage(wixCodeApi)
      const ssr = flowAPI.environment.isSSR

      const serverApi = new Api(controllerParams.controllerConfig)
      const controller = controllerParams.controllerConfig
      const [pageUrl, initialData, paidPlansEnabled, membersAreaEnabled, groupsInstalled, experiments] =
        await Promise.all([
          getPageUrl(wixCodeApi),
          getInitialData(serverApi, controller, controller.config.style.styleParams),
          wixCodeApi.site.isAppSectionInstalled({
            appDefinitionId: PAID_PLANS_APP_DEF_ID,
            sectionId: PAID_PLANS_SECTION_ID,
          }),
          isMembersEventsPageInstalled(wixCodeApi),
          wixCodeApi.site.isAppSectionInstalled({
            appDefinitionId: GROUPS_APP_DEF_ID,
            sectionId: GROUPS_SECTION_ID,
          }),
          getExperimentsByScope(wixCodeApi),
        ])

      const store = createStore(
        controllerParams,
        {...initialData, experiments, membersAreaEnabled, paidPlansEnabled, groups: {installed: groupsInstalled}},
        serverApi,
      )

      await Promise.all([store.dispatch(setBaseEnvironment() as any), store.dispatch(fetchCurrentMember() as any)])

      await store.dispatch(getMemberRsvp() as any)

      store.dispatch(addLoginListener() as any)

      watchInstance(controller, store.dispatch)

      await store.dispatch(handleInitialNavigation() as any)

      addLocationListener(controller.wixCodeApi, store)
      subscribeToStateChanges(controller, store)

      const actions = getExportedActions(store)

      const props: DetailsPageProps = {
        state: store.getState(),
        actions,
        isRTL: isRtlLanguage(language),
        staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
        pageUrl,
      }

      controller.setProps(props)

      if (!ssr) {
        const event = store.getState().event
        store.dispatch(getExtraEventData(event) as any)
      }
    },
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

const createStore = (controllerParams: ControllerParams, initialData: any, serverApi: any) => {
  const {controllerConfig: controller} = controllerParams
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState},
    extraArguments: {serverApi, wixCodeApi, compId, platformAPIs, flowAPI: controllerParams.flowAPI},
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}

const getInitialData = async (
  serverApi: Api,
  controller: IWidgetControllerConfig,
  styleParams: IWixStyleParams,
): Promise<Partial<State>> => {
  const {wixCodeApi, appParams, config} = controller
  const navigation = parseLocation(wixCodeApi)
  const {slug} = navigation
  const responsiveEditor = isResponsiveEditor(config)
  const draftPreviewToken = getDraftToken(wixCodeApi)

  const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
  const currentUser = wixCodeApi.user.currentUser

  const [{event, component, siteSettings, demoEvents, tickets, policies, schedule, dates, upcomingOccurrencesCount}] =
    await Promise.all([
      serverApi.getData({
        slug,
        responsive: responsiveEditor,
        schedulePageInstalled,
        draftPreviewToken,
      }),
    ])
  const instance = appParams.instance

  const invoiceState = await getStateFromInvoice({serverApi, event, navigation})

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsiveEditor) : event,
    siteSettings,
    demoEvents,
    tickets,
    schedule,
    upcomingOccurrencesCount,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    component: {
      id: component.id,
      settings: {
        ...component.config.settings,
        ...styleParams.numbers,
        ...styleParams.booleans,
      },
    },
    instance: {
      instance,
      ...decodeInstance(instance),
    },
    policies: {
      agreed: false,
      ...policies,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
    ...invoiceState,
  }
}

const getStateFromInvoice = async ({
  serverApi,
  event,
  navigation,
}: {
  serverApi: Api
  event: wix.events.Event
  navigation: Navigation
}) => {
  if (navigation.route === DETAILS_ROUTE.TICKET_FORM && event?.id) {
    const {
      query: {reservationId},
    } = navigation

    if (reservationId && reservationId !== 'undefined') {
      const invoice = await serverApi.getInvoice(event.id, reservationId)
      if (invoice.reservationStatus === ReservationStatus.RESERVATION_PENDING) {
        const selectedTickets = invoice.invoice.items.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.quantity,
          }
        }, {} as Record<string, number>)

        return {
          invoice: {
            ...defaultInvoiceState,
            invoice: invoice.invoice,
          },
          selectedTickets,
          reservation: {
            data: {
              id: reservationId,
              expires: invoice.expires,
            },
            error: null,
          },
        }
      }
      return {}
    }
  }
  return {}
}

export default createDetailsPageController
