import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {DemoEvents} from '../../../commons/types/state'

export const getDemoEvent = (demoEvents: DemoEvents, slug: string, responsiveEditor: boolean) => {
  let demoEventNumber = Number(slug.replace('demo', '')) || 0
  demoEventNumber = Math.max(Math.min(demoEventNumber, 3), 0)

  if (demoEventNumber === 0) {
    return responsiveEditor ? demoEvents[WIDGET_TYPE.LIST][0] : demoEvents[WIDGET_TYPE.SINGLE][0]
  } else {
    return demoEvents[WIDGET_TYPE.LIST][demoEventNumber - 1]
  }
}
